html,
body {
  margin: 0 !important;
  padding: 0 !important;
  min-height: 100vh !important;
  overflow: auto;
}

* {
  font-family: "Fredoka", sans-serif;
}

body,
#app {
  background: #f2f1f4;
}

#router-view {
  overflow: hidden;
  min-height: 100vh;
}

p {
  font-size: 1rem;
}

a {
  color: #1c73f4;
}

small {
  font-size: 0.95rem;
}

.clearfix {
  clear: both;
}

.grayed-out {
  opacity: 0.5;
}

.ikleeralles {
  font-family: "Just Another Hand", cursive;
  font-size: 6rem !important;
  font-weight: 400 !important;
  text-align: center;

  .orange {
    color: #fc7234;
    font-family: inherit;
  }

  &.white {
    color: #fff;
  }
}

.hoverable:hover {
  cursor: pointer;
}

.anotherhand {
  font-family: "Just Another Hand", cursive;
  font-weight: 300 !important;
}

.orange {
  color: #fc7234;
}

.strong, strong, .has-text-weight-bold {
  font-weight: 600 !important;
}

.container {
  padding: 0 30px 0 30px;
  max-width: 1900px;
}

.full-width {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .container {
    padding: 0 10px 0 10px;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 60px);
  &.center-content {
    justify-content: center;
    align-items: center;
  }
}

.section {
  padding: 0;
}

.card {
  box-shadow: none;
  border-radius: 20px;
  padding: 20px;
  border: 2px solid #e2e9e9;
  border-bottom-width: 4px;
  transition: all 0.2s;
  &.card-dotted {
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: dashed;
    border-width: 2px;
    height: 100%;
  }
  &.card-hoverable,
  &.card-dotted {
    &:hover {
      cursor: pointer;
      border-color: #209cee;
    }
  }
  &.no-border {
    border: 0;
  }
}

.modal {
  z-index: 200;
  &.is-active {
    z-index: 100;
  }
}

.modal-card {
  padding: 10px;
  .modal-card-head {
    border-bottom: 0;
  }

  .modal-card-foot {
    border-top: 0;
  }

  .modal-card-body {
    background-color: whitesmoke;
    border: 0 !important;
  }
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
}

.tag {
  background-color: white;
}

hr {
  height: 1px;
  background-color: rgb(221, 221, 221);
}

.is-centered {
  display: block;
  margin: 0 auto;
}

input.input {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0 20px;
  border-width: 0;
  font-family: "Fredoka", sans-serif;
  font-size: 1.2em;
  border: 2px solid transparent !important;

  &:focus {
    border: 2px solid #4189f4 !important;

    + label {
      color: #4189f4 !important;
    }
  }
  &.is-medium {
    font-size: 1.4em;
    &[type="number"] {
      font-size: 1.25em;
    }
  }
}

.control.is-loading::after {
  top: 0.625em;
}

textarea.textarea {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  padding: 20px;
  border-width: 0;
  font-family: "Fredoka", sans-serif;
  font-size: 1.2em;

  &:focus {
    border: 2px solid #4189f4 !important;

    + label {
      color: #4189f4 !important;
    }
  }
}

.select,
select.is-medium {
  select {
    border-radius: 10px !important;
    padding-left: 20px !important;
    background-color: #fff;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    border-width: 0;
    font-family: "Fredoka", sans-serif;
    font-size: 1.2em;

    &:focus {
      border: 2px solid #4189f4 !important;

      + label {
        color: #4189f4 !important;
      }
    }
  }
}

.select:not(.is-multiple):not(.is-loading)::after {
  top: 1.5em;
}

.input {
  &.bigger {
    height: 3em;
  }
}

.upload-draggable {
  border: 2px dashed rgb(222, 222, 222) !important;
  padding: 20px !important;
}

.hero {
  display: flex;
  align-items: center;
}

.hero.is-light {
  background: #f5f5f5;
}

.hero.is-white {
  background: #fff;
}

label.label {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: 0 !important;
}

.button {
  border-radius: 50px;
  font-weight: normal;
  font-family: "Fredoka", sans-serif;
  padding-left: 20px !important;
  padding-right: 20px !important;
  height: 2.5em;
  transform-style: preserve-3d;
  position: relative;
  border: 1px solid transparent;
  border-bottom: 3px solid;
  border-color: #D1D1D1;

  &.is-large {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  &.is-medium {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  &.is-small,
  &.is-large {
    border-radius: 50px !important;
  }

  &.is-primary {
    border-bottom: 3px solid darken($primary, 10%);
  }

  &.is-success {
    border-bottom: 3px solid darken($green, 10%);
  }

  &.is-info {
    border-bottom: 3px solid darken($link, 10%);
  }

  &.is-default {
    border-bottom: 3px solid darken(#D1D1D1 , 10%);
  }

  &.is-danger {
    border-bottom: 3px solid darken($red , 10%);
  }

  &:hover {
    transition: all 0.1s;
    border-bottom-width: 1px;
  }
}

.full-screen {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.checkbox.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 23px;
  margin-top: -25px;
  padding-top: 2px;
  cursor: pointer;
  font-size: 16px;
}

.checkbox.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control.is-loading::after {
  height: 1.5em;
  width: 1.5em;
}

.checkbox.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px solid #e2e9e9;
  box-sizing: content-box;
  border-radius: 50%;
}

.checkbox.control:hover input ~ .checkbox.control_indicator,
.checkbox.control input:focus ~ .checkbox.control_indicator {
  background: #fff;
}

.checkbox.control input:checked ~ .checkbox.control_indicator {
  background: #209cee;
  border: 2px solid #209cee;
}

.checkbox.control input:disabled ~ .checkbox.control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.checkbox.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.checkbox.control input:checked ~ .checkbox.control_indicator:after {
  display: block;
}

.checkbox.control-checkbox .checkbox.control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox.control-checkbox input:disabled ~ .checkbox.control_indicator:after {
  border-color: #7b7b7b;
}

.checkbox.control-checkbox .checkbox.control_indicator::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.441rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #209cee;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

.answer.textarea {
  border: none !important;
  box-shadow: none;
  resize: none;
  padding: 0;
  font-size: 30px;
  font-weight: 600;
  min-height: 5em;
  max-height: 10em;

  &.correct {
    color: #9bc53d !important;
  }

  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.b-steps {
  nav.steps {
    margin-bottom: 5%;
  }
}

.step-item {
  width: 70%;
  margin: 0 auto;

  &.tight {
    width: 50%;
  }
}

.loading-icon:after {
  content: url("~@/assets/loading.svg") !important;
  transform: scale(0.5);
  opacity: 0.5;
  -webkit-animation: none !important;
  animation: none !important;
  border: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.image {
  &.is-256x256 {
    height: 256px;
    width: 256px;
  }

  &.is-128x256 {
    height: 128px;
    width: 256px;
  }

  &.is-centered {
    margin: 0 auto;
  }
}

.is-horizontal-paddingless {
  padding-left: 0;
  padding-right: 0;
}

.dropdown.is-active {
  z-index: 1000;
}

.dropdown-content {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  .dropdown-item {
    &:hover {
      background: #1d73f4;
      color: #fff;
    }
  }
}

.color-dropdown {
  .dropdown-menu {
    min-width: 44px;
    max-width: 44px;

    .dropdown-content {
      border-radius: 20px;
      padding: 0;
    }

    .dropdown-item {
      overflow: hidden;
      padding: 6px 4px;
      border-radius: 20px;
      display: flex;
      justify-content: center;

      .dot {
        width: 36px;
        height: 36px;
        background: #eee;
        margin: 0 auto;

        &.red {
          background: #f14668 !important;
        }

        &.green {
          background: #9bc53d !important;
        }

        &.pink {
          background: #ff33cc !important;
        }

        &.blue {
          background: #4189f4 !important;
        }

        &.yellow {
          background: #ffff00 !important;
        }
      }
    }
  }
}

.flex-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-drag-select-area-overlay {
  border: 1px dotted gray !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.fa-lg {
  font-size: 1em;
  padding-top: 5px;
}

.color-red {
  color: $red !important;
}

.color-green {
  color: $green !important;
}

.color-pink {
  color: #ff33cc !important;
}

.color-blue {
  color: $blue !important;
}

.color-yellow {
  color: $yellow !important;
}

.color-dark {
  color: #2b2b2b !important;
}

/* B-TABS */
nav.tabs {
  border-radius: 10px !important;
  background: #f3f3f3;
  ul {
    border-bottom: 0;
    li {
      margin: 3px;
      a {
        border-radius: 10px !important;
        padding: 2px 0;
        margin-bottom: 0;
        border: 0 !important;
        &:hover {
          border-bottom: 0;
          background-color: transparent !important;
        }
      }
      &.is-active a {
        border: 0 !important;
        background: #fff !important;
        color: #000;
        font-weight: 600;
        @extend .color-blue;
      }
    }
  }
}

.tab-content {
  padding: 10px 0 !important;
}

.breadcrumb {
  li {
    a {
      color: #1d73f4 !important;
    }
    &.is-active a {
      color: black !important;
    }
  }
}

.title {
  &.is-4 {
    margin-bottom: 0.5rem;
  }
}

.icon {
  &.is-left,
  &.is-right {
    height: 100% !important;
  }
}

#confetti-canvas {
  z-index: 1000;
}

.hide-tablet {
  @media only screen and (max-width: 1200px) {
    .container {
      padding: 0 10px 0 10px;
    }
  }
}
