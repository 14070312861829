.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.slide-leave-to {
  transform: translateY(-20px);
}
